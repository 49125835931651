import * as React from 'react';
import { styles } from 'src/components/details/trialPurpose/trialPurpose.styles';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ProtocolForm } from 'components/fetch/models/studyDetails';
import { Col, Container } from 'reactstrap';
import Row from 'reactstrap/lib/Row';
import classNames from 'classnames';
import { getAgeLabel } from 'src/utils/studyFormHelpers';
import InlineInfo from 'components/common/popover/inlineInfo';
import { WithStrings, withStrings } from 'components/locale';
import { LocaleStrings } from 'components/locale/strings';
import ThemedIcon from 'components/details/themedIcon/themedIcon';

interface Props extends WithStyles<typeof styles>, WithStrings<typeof getStrings> {
    study: ProtocolForm;
    onReadMore: () => void;
}

class TrialPurpose extends React.Component<Props> {
    render = () => {
        const { classes, study, strings, format } = this.props;
        const { display_gender, minimum_age, maximum_age } = study;
        return (
            <div>
                <Container className={classes.purposeContainer}>
                    <Row>
                        <Col className={'text-center'}>
                            <div className={classes.trialSection}>
                                <h2 className={classes.trialHeader}>
                                    {strings.trialPurpose}
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Row>
                                <Col
                                    className={classNames([
                                        classes.trialLabel,
                                        'text-center',
                                    ])}
                                >
                                    {strings.keyParticipationRequirements}
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'text-center'}>
                                    <div className={classNames([
                                        display_gender==='Female' ? classes.femaleIcon : (display_gender === 'Male' ? classes.maleIcon : '' ),
                                        classes.trialSubsection,
                                    ])}>
                                        <ThemedIcon icon={'gender'} />
                                    </div>
                                    <span className={classes.trialLabel}>
                                        {display_gender}
                                    </span>
                                </Col>
                                <Col className={'text-center'}>
                                    <div className={classes.trialSubsection}>
                                        <ThemedIcon icon={'age'} />
                                    </div>
                                    <span className={classes.trialLabel}>
                                        {format(strings.common.yearsValue, { years: getAgeLabel(minimum_age, maximum_age) })}
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col
                                    className={classNames([
                                        classes.trialLabel,
                                        'text-center',
                                    ])}
                                >
                                    {strings.requirementInformation}
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'text-center'}>
                                    <div className={classes.trialSubsection}>
                                        <ThemedIcon icon={'inclusion'} />
                                    </div>
                                    <span
                                        className={classNames([
                                            classes.trialLabel,
                                            'text-center',
                                        ])}
                                    >
                                        {strings.inclusionCriteria}
                                        <InlineInfo
                                            header={
                                                strings.inclusionCriteria
                                            }
                                            body={
                                                strings.inclusionInfo
                                            }
                                        />
                                    </span>
                                </Col>
                                <Col className={'text-center'}>
                                    <div className={classes.trialSubsection}>
                                        <ThemedIcon icon={'exclusion'} />
                                    </div>
                                    <span
                                        className={classNames([
                                            classes.trialLabel,
                                            'text-center',
                                        ])}
                                    >
                                        {strings.exclusionCriteria}
                                        <InlineInfo
                                            header={
                                                strings.exclusionCriteria
                                            }
                                            body={
                                                strings.exclusionInfo
                                            }
                                        />
                                    </span>
                                    
                                </Col>
                                <Col xs={12}>
                                    <div className={classes.readMore}>
                                            <a href={'#'} onClick={this.onReadMore}>
                                                {strings.common.readMore}
                                            </a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };

    private onReadMore = (
        evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        evt.preventDefault();
        this.props.onReadMore();
    };
}

const getStrings = ({ studyDetails, common }: LocaleStrings) => ({
    ...studyDetails.criteria,
    ...studyDetails.trialPurpose,
    common
});

export default withStyles(styles)(withStrings(TrialPurpose, getStrings));
