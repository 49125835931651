import * as React from 'react';
import { styles } from 'src/components/details/trialSummary/trialSummary.styles';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ProtocolForm } from 'components/fetch/models/studyDetails';
import { Col, Container } from 'reactstrap';
import Row from 'reactstrap/lib/Row';
import { getConditionHeader, getLookupConditionLabel } from 'src/utils/studyFormHelpers';
import InlineInfo from 'components/common/popover/inlineInfo';
import { withStrings, WithStrings } from 'components/locale';
import { LocaleStrings } from 'components/locale/strings';
import ThemedIcon from 'components/details/themedIcon/themedIcon';

interface Props extends WithStyles<typeof styles>, WithStrings<typeof getStrings> {
    study: ProtocolForm;
}

class TrialSummary extends React.Component<Props> {
    render = () => {
        const { classes, study, strings } = this.props;
        const {
            conditions,
            start_date,
            primary_compl_date,
            display_phase,
            study_type,
            healthy_volunteers,
            sponsors,
        } = study;
        return (
            <div className={classes.summaryContainer}>
                <Container>
                    <Row>
                        <Col className={'text-center'}>
                            <div className={classes.trialSection}>
                                <h2 className={classes.trialHeader}>
                                    {strings.trialSummary}
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col className={'text-center'} md={4}>
                                    <div className={classes.trialSubsection}>
                                        <ThemedIcon icon={'medicalCondition'} />
                                    </div>
                                    <div className={classes.trialLabel}>
                                        {strings.medicalCondition}
                                        <InlineInfo
                                            header={strings.medicalCondition}
                                            body={strings.medicalConditionInfo}
                                        />
                                    </div>
                                    <div
                                        className={classes.trialSubsectionLabel}
                                    >
                                        {'COVID-19' + `${getLookupConditionLabel(conditions) && (', ' + getLookupConditionLabel(conditions))}`}
                                    </div>
                                </Col>
                                <Col className={'text-center'} md={4}>
                                    <div className={classes.trialSubsection}>
                                        <ThemedIcon icon={'duration'} />
                                    </div>
                                    <div className={classes.trialLabel}>
                                        {strings.trialDuration}
                                        <InlineInfo
                                            header={strings.trialDuration}
                                            body={strings.trialDurationInfo}
                                        />
                                    </div>
                                    <div
                                        className={classes.trialSubsectionLabel}
                                    >
                                        {this.getDuration(
                                            start_date,
                                            primary_compl_date
                                        )}
                                    </div>
                                </Col>
                                <Col className={'text-center'} md={4}>
                                    <div className={classes.trialSubsection}>
                                        <ThemedIcon icon={'phase'} />
                                    </div>
                                    <div className={classes.trialLabel}>
                                        {strings.phase}
                                        <InlineInfo
                                            header={strings.phase}
                                            body={strings.studyPhaseInfo}
                                        />
                                    </div>
                                    <div
                                        className={classes.trialSubsectionLabel}
                                    >
                                        {this.prepareString(display_phase)}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'text-center'} md={4}>
                                    <div className={classes.trialSubsection}>
                                        <ThemedIcon icon={'studyType'} />
                                    </div>
                                    <div className={classes.trialLabel}>
                                        {strings.studyType}
                                        <InlineInfo
                                            header={strings.studyType}
                                            body={strings.studyTypeInfo}
                                        />
                                    </div>
                                    <div
                                        className={classes.trialSubsectionLabel}
                                    >
                                        {this.prepareString(study_type)}
                                    </div>
                                </Col>
                                <Col className={'text-center'} md={4}>
                                    <div className={classes.trialSubsection}>
                                        <ThemedIcon icon={'volunteers'} />
                                    </div>
                                    <div className={classes.trialLabel}>
                                        {strings.acceptsHealthyVolunteers}
                                        <InlineInfo
                                            header={
                                                strings.acceptsHealthyVolunteers
                                            }
                                            body={strings.acceptHealthyInfo}
                                        />
                                    </div>
                                    <div
                                        className={classes.trialSubsectionLabel}
                                    >
                                        {this.prepareString(healthy_volunteers)}
                                    </div>
                                </Col>
                                <Col className={'text-center'} md={4}>
                                    <div className={classes.trialSubsection}>
                                        <ThemedIcon icon={'sponsor'} />
                                    </div>
                                    <div className={classes.trialLabel}>
                                        {strings.sponsor}
                                        <InlineInfo
                                            header={
                                                strings.sponsor
                                            }
                                            body={strings.sponsorInfo}
                                        />
                                    </div>
                                    <div
                                        className={classes.trialSubsectionLabel}
                                    >
                                        {this.prepareString(sponsors.lead_sponsor)}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };
    private prepareString = (val: string) =>
        val || this.props.strings.common.notAvailable;

    private getDuration = (start: string, end: string) => {
        const { strings } = this.props;
        if (!start && !end) {
            return strings.common.notAvailable;
        }
        const startDate = new Date(start);
        if (!end) {
            return `${this.toStringDate(startDate)} - ${strings.present}`;
        }
        const endDate = new Date(end);
        return `${this.toStringDate(startDate)} - ${this.toStringDate(
            endDate
        )}`;
    };

    private toStringDate = (date: Date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en', options);
    };
}

const getStrings = ({ common, studyDetails }: LocaleStrings) => ({ common, studyDetails, ...studyDetails.trialSummary });

export default withStyles(styles)(withStrings(TrialSummary, getStrings));
