import { createStyles } from '@material-ui/styles';
import { styles as trialSectionsStyles } from 'components/details/common/sections.styles';
import { Theme } from 'components/common/theme/default';

export const styles = (theme: Theme) => createStyles({
    ...trialSectionsStyles(theme),
    summaryContainer: {
        background: theme.color.advancedBackground,
        minHeight: '440px',
        width: '100%',
    },
    trialSubsection: {
        padding: '0 0 20px 0',
        '& #Shape':{
            fill:theme.color.icon
        },
    },
    trialSubsectionLabel: {
        color: theme.color.inputText,
        paddingBottom: '60px',
        fontSize: '18px',
    },
});
