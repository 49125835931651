import { createStyles } from '@material-ui/styles';
import { Theme } from 'components/common/theme/default';

export const styles = (theme: Theme) => createStyles({
    '@global': {
        '.popover-inner': {
            padding: '30px',
            '&::after': {
                top: 0,
                content: "''",
                left: '-0.5rem',
                width: '0.5rem',
                height: '100%',
                position: 'absolute',
                background: 'transparent',
            },
            '& h3': {
                fontWeight: 500,
                color: theme.color.title,
                fontSize: '30px',
                marginBottom: '18px',
            },
        },
        '.popover': {
            boxShadow: '0 30px 40px 0 rgba(0,0,0,0.2)',
            padding: '0',
            border: 'none',
            borderRadius: 0,
            maxWidth: '735px',
            fontFamily: theme.fontFamily,
            fontSize: theme.fontSize,
            [theme.breakpoints.down('md')]: {
                maxWidth: '100%',
            },
        },
    },
});
