import { createStyles } from '@material-ui/styles';
import { Theme } from 'components/common/theme/default';

export const styles = (theme: Theme) => createStyles({
    smTitleCol: {
        '& svg': {
            [theme.breakpoints.down('sm')]: {
                width:'40px!important',
                height:'40px!important',
            }
        },
        '& h3': {
            [theme.breakpoints.down('sm')]: {
                display: 'inline',
            },
            display: 'none',
            paddingLeft: '0.5em',
            verticalAlign: 'middle'
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            marginBottom: '20px',
        },
        textAlign: 'right',
    },
    mdTitle: {
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
        verticalAlign: 'middle',
        display: 'none',
    },
    messageBody: {
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        fontFamily: theme.fontFamily,
    },
    secondRow: {
        marginTop: '40px',
    },
});
