import { createStyles } from '@material-ui/styles';
import { Theme } from 'components/common/theme/default';

export const styles = (theme: Theme) => createStyles({
    detailsContainer: {
        width: '100%',
        padding: 0,
        margin: 0,
        background: theme.color.filtersBackground,
        '& .gm-style-iw': {
            paddingTop: 0,
            marginTop: '-10px',
            '& .gm-style-iw-d': {
                maxHeight: '200px !important',
            }
        },
    },
});
