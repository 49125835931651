import { createStyles } from '@material-ui/styles';
import { styles as trialSectionsStyles } from 'components/details/common/sections.styles';
import { iconsStyles } from 'components/common/theme/styles';
import { Theme } from 'components/common/theme/default';

export const styles = (theme: Theme) => createStyles({
    ...trialSectionsStyles(theme),
    ...iconsStyles,
    noMargin:{
        margin:0,
    },
    mapContainer: {
        position: 'relative',
        margin: '0 auto',
        height: '650px',
    },
    infoWindow: {
        textAlign: 'left',
        maxWidth: '300px',
        '& strong': {
            fontWeight: 500,
        },
        '& p': {
            marginBottom: '8px',
            marginTop: '22px',
        }
    },
    locationsListContainer: {
        margin: '0 auto 60px auto',
        maxHeight: '700px',
        overflow: 'hidden',
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            margin: '30px 0 30px 0',
            maxHeight: 'initial',
            maxWidth: '100%',
            width: '100%',
        },
    },
    locationsListHeaders: {
        borderBottom: `1px solid ${theme.color.background}`,
        color: theme.color.sectionName,
        padding: '30px 0 15px 0',
        margin: '0 auto',
        minHeight: '59px',
        fontSize: '21px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    locationListItem: {
        fontSize: '16px',
        color: theme.color.inputText,
        padding: '30px 10px 30px 10px',
        verticalAlign: 'middle',
        '& span': {
            verticalAlign: 'middle',
        },
        '& img': {
            marginRight: '10px',
        },
        '&:nth-child(odd)': {
            background: theme.color.filtersBackground,
            [theme.breakpoints.down('sm')]: {
                background: theme.color.advancedBackground,
            },
        },
        '&:nth-child(even)': {
            background: theme.color.advancedBackground,
            [theme.breakpoints.down('sm')]: {
                background: theme.color.filtersBackground,
            },
        },
    },
    contactButton: {
        width: '110px',     
        color: '#00ACD9',
        border: '2px solid #00ACD9',
        padding: '0.5em 0.6em',
        background:'transparent',
        '&:hover':{
            color:'white',
            background:'#00ACD9',            
            border: '2px solid #00ACD9',
        }
     
    },
    itemSmallHeader: {
        fontWeight: 600,
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            padding: '0 30px 20px 30px',
            display: 'inline-block',
            verticalAlign: 'top',
            width: '35%',
        },
    },
    itemSmallContent: {
        width: '100%',
        display:'flex',
        '& span':{                
            [theme.breakpoints.up('sm')]: {
                width:'calc(100% - 30px)',
            },
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 30px 20px 5px',
            display: 'inline-block',
            width: '65%',
        },
    },
    buttonContainer: {
        textAlign: 'center',
        paddingTop: '0',
    }
});
