import { createStyles } from "@material-ui/styles";
import { styles as trialSectionsStyles } from "components/details/common/sections.styles";
import { Theme } from "components/common/theme/default";

export const styles = (theme: Theme) => {
  const trialSection = trialSectionsStyles(theme);
  return createStyles({
    ...trialSection,
    purposeContainer: {
      minHeight: "440px",
      paddingBottom: "60px",
      "& a": {
        color: "#00ACD9",
        cursor: "pointer",
        display: "block",
        textDecoration: "underline",
      },
    },
    trialSection: {
      ...trialSection.trialSection,
      paddingBottom: 0,
    },
    trialLabel: {
      ...trialSection.trialLabel,
      paddingTop: "30px",
    },
    femaleIcon: {
      "& svg": {
        "& #Male": {
          fill: "#d8d8d8 !important",
        },
      },
    },
    maleIcon: {
      "& svg": {
        "& #Female": {
          fill: "#d8d8d8 !important",
        },
      },
    },
    readMore: {
      textAlign: "center",
      [theme.breakpoints.up("xl")]: {
        paddingRight: "20px",
      },
      "& a": { display: "inline" },
    },
  });
};
