import withTheme, { WithTheme } from '@material-ui/styles/withTheme';
import { Theme } from 'components/common/theme/default';
import * as icons from './icons';
import * as React from 'react';

export type IconKey = keyof typeof icons;

interface Props extends WithTheme<Theme> {
    icon: IconKey;
}

const ThemedIcon = ({ icon, theme }: Props) => {
    const Icon = icons[icon];
    return <Icon style={{
        height: '65px',
        width: '65px',
        color: theme.color.icon,
    }} />;
};

export default withTheme(ThemedIcon);
