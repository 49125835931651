import { styles } from './trialLocation.styles';
import { withStyles, WithStyles } from '@material-ui/styles';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { Location, ProtocolForm } from 'components/fetch/models/studyDetails';
import Button from 'components/common/button/button';
import { withStrings, WithStrings } from 'components/locale';
import { FacilityLocation } from 'components/details/contactForm/contactForm';
import classNames from 'classnames';
import {
    Map,
    Marker,
    GoogleApiWrapper,
    MapProps,
    InfoWindow,
} from 'google-maps-react';
import { detect as detectBrowser } from 'detect-browser';
import { WithTenant, withTenant } from 'components/loadTenant/withTenant';

interface Props extends WithStrings, MapProps, WithStyles<typeof styles>, WithTenant {
    study: ProtocolForm;
    searchLat: any;
    searchLong: any;
    searchRadius: any;
    baseLat: any;
    baseLong: any;
    onContactUs: (facility: FacilityLocation) => void;
    onAskToJoin: (facility: FacilityLocation) => void;
}
interface State {
    activeMarker: any;
    selectedPlace: any;
    showingInfoWindow: boolean;
    activeLocations: Location[];
}

class TrialLocation extends React.Component<Props, State> {
    private mapRef: any;
    private markersRefs: any[] = [];
    private markers: any[] = [];
    private markerDescriptions: Object = {};
    constructor(props: Props) {
        super(props);
        this.state = {
            activeMarker: {},
            selectedPlace: {},
            showingInfoWindow: false,
            activeLocations: [],
        };
    }
    componentDidMount(): void {
        this.markers = [];
        if (!this.mapRef) {
            return;
        }
        for (const ref of this.markersRefs) {
            ref.marker.setMap(this.mapRef.map);
            this.markers = this.markersRefs;
        }
        this.props.study.locations.forEach((row) => {
            if (row.Latitude && row.Longitude) {
              const key =
                '' +
                row.Latitude +
                '_' +
                row.Longitude;
              if (typeof this.markerDescriptions[key] === 'undefined') {
                this.markerDescriptions[key] = [];
              }
              this.markerDescriptions[key].push(row);
            }
        })
        if (this.markersRefs.length > 1) {
            if (this.props.baseLat) {
            let locations = this.sortLocations(
                this.props.study.locations,
                this.props.baseLat,
                this.props.baseLong,
                'dis'
              );
              this.mapRef.map.setZoom(7);
              this.mapRef.map.setCenter({
                lat: parseFloat(this.props.baseLat),
                lng: parseFloat(this.props.baseLong),
              });
              {this.setState({
                  activeLocations: locations
              })}
            }
            else {
            this.mapRef.map.setZoom(2);
            }
        }
            (window as any).google.maps.event.addListener(
            this.mapRef.map,
            'bounds_changed',
            () => {
              this.onBoundsChanged();
            }
          );
    }

    render = () => {
        console.log(this.markerDescriptions, 'test')
        const { classes, study, strings } = this.props;
        const { locations } = study;
        return (
            <div>
                <div>
                    <Row className={classes.noMargin}>
                        <div className={'text-center container'}>
                            <div className={classes.trialSection}>
                                <h2 className={classes.trialHeader}>
                                    {strings.studyDetails.trialLocation.whereToParticipate}
                                </h2>
                            </div>
                        </div>
                    </Row>
                    <Row className={classes.noMargin}>
                        <div className={'text-center container'}>
                            {this.getMap(locations)}
                        </div>
                    </Row>
                    <Row className={classes.noMargin}>
                        <div className={'container'}>
                            <Row className={classes.locationsListHeaders}>
                                <Col md={6}>
                                    <span>{strings.studyDetails.trialLocation.location}</span>
                                </Col>
                                <Col md={3}>
                                    <span>{strings.studyDetails.trialLocation.status}</span>
                                </Col>
                                <Col md={3} />
                            </Row>
                            <div className={classes.locationsListContainer}>
                                {this.getLocations(locations)}
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
        );
    };

    private getMap = (locations: Location[]) => {
        const { classes } = this.props;
        if (locations && locations.length === 0) {
            return null;
        }
        const { Latitude, Longitude } = locations && locations[0];
        return (
            <div className={classes.mapContainer}>
                <Map
                    ref={ref => {
                        this.mapRef = ref;
                    }}
                    google={this.props.google}
                    initialCenter={{
                        lat: parseFloat(Latitude) || 0,
                        lng: parseFloat(Longitude) || 0,
                    }}
                >
                    {this.getMarkers(locations)}
                    {this.getInfoWindow()}
                </Map>
            </div>
        );
    };

    private calculateDistance(locLat: any, locLong: any, paramLat:any, paramLong: any) {
        const convertToRadians = (num: any) => {
          return (num * Math.PI) / 180;
        };
        let distance =
          Math.sin(convertToRadians(paramLat)) *
            Math.sin(convertToRadians(locLat)) +
          Math.cos(convertToRadians(paramLat)) *
            Math.cos(convertToRadians(locLat)) *
            Math.cos(convertToRadians(locLong) - convertToRadians(paramLong));
        //Calculate distance in miles, Earth radius in miles = 3959
        distance = Math.acos(distance) * 3959;
        return distance;
      }
    private sortLocations(paramRes: any[], paramLat: any, paramLong: any, paramName: string) {
        if (paramLong != null && paramLat != null) {
          paramRes.forEach(loc => {
            loc[paramName] = this.calculateDistance(
              loc['Latitude'],
              loc['Longitude'],
              paramLong,
              paramLat
            );
          });
    
          paramRes.sort(function(a, b) {
            return a.distance - b.distance;
          });
          return paramRes;
        } else {
          return paramRes;
        }
    }

    private onBoundsChanged = () => {
        let currentLocations: any[] = [];
        let activeLocationss: any[] = [];
        let uniqueLocations: any[] = [];
        const { study } = this.props;
        const { locations } = study;
        for (var i = 0; i < this.markers.length; i++) {
          if (this.mapRef.map.getBounds().contains(this.markers[i].marker.getPosition())) {
            let key = '';
            currentLocations.push({
              Latitude: this.markers[i].marker.position.lat(),
              Longitude: this.markers[i].marker.position.lng(),
              key: key.concat(this.markers[i].marker.position.lat(), this.markers[i].marker.position.lng()),
            });
            // markers[i] in visible bounds
          } else {
            // markers[i] is not in visible bounds
          }
        }
        uniqueLocations = Array.from(new Set(currentLocations.map(a => a.key))).map(
          key => {
            return {
              Latitude: currentLocations.find(s => s.key === key).Latitude,
              Longitude: currentLocations.find(s => s.key === key).Longitude,
            };
          }
        );
        locations.map(item => {
          uniqueLocations.map(activeItem => {
            if (
              item.Latitude.includes(activeItem.Latitude) &&
              item.Longitude.includes(activeItem.Longitude)
            ) {
                activeLocationss.push(item);
            }
          });
        });
        
        this.setState({activeLocations: activeLocationss});
        
      };    

    private getLocations = (locations: Location[]) => {
        const { classes, strings } = this.props;
        if (!locations || locations.length === 0) {
            return (
                <Row>
                    <Col>
                        <p>{strings.studyDetails.trialLocation.noLocationForStudy}</p>
                    </Col>
                </Row>
            );
        }
        else {
            let locations = this.sortLocations(
                this.state.activeLocations,
                this.props.searchLong,
                this.props.searchLat,
                'distance'
              );
        return locations.map((location, index) => {
            const places = [
                location.City,
                location.StateDisplay,
                location.CountryDisplay,
                location.PostalCode,
            ].filter(el => el);
            return (
                <Row
                    key={`location-${index}`}
                    className={classes.locationListItem}
                >
                    <Col className={'align-middle'} md={6}>
                        <div className={classes.itemSmallHeader}>
                            {strings.studyDetails.trialLocation.location}
                        </div>
                        <div className={classes.itemSmallContent}>
                            <span>{places.join(', ')}</span>
                        </div>
                    </Col>
                    <Col className={'align-middle'} md={3}>
                        <div className={classes.itemSmallHeader}>
                            {strings.studyDetails.trialLocation.status}
                        </div>
                        <div className={classes.itemSmallContent}>
                            {
                                location.StatusDisplay ? (
                                    <i
                                        className={classNames([
                                            classes.smallIcon,
                                            'align-middle',
                                        ])}
                                        data-icon={
                                            location.StatusMapped &&
                                            location.StatusMapped.toUpperCase()
                                        }
                                    />
                                ) : ''
                            }
                            
                            <span>                                
                                {location.StatusDisplay || strings.common.notAvailable}
                            </span>
                        </div>
                    </Col>
                    <Col className={'align-middle'} md={3}>
                        <div className={classes.buttonContainer}>
                            {this.getContactUsButton(location)}
                        </div>
                    </Col>
                </Row>
            );
        });
    }
    };

    private getMarkers(locations: Location[]) {
        if (!locations || locations.length === 0) {
            return null;
        }

        return locations
            .filter(location => location.Latitude && location.Longitude)
            .map((location, index) => {
                const google = (window as any).google;
                const latLng = new google.maps.LatLng(
                    location.Latitude,
                    location.Longitude
                );
                this.markersRefs = [];
                return (
                    <Marker
                        ref={ref => this.markersRefs.push(ref)}
                        key={index}
                        position={latLng}
                        draggable={false}
                        title={location.Name}
                        icon={this.getIconUrl()}
                        onClick={this.onMarkerClick(location)}
                    />
                );
            });
    }

    private getIconUrl = () => {
        const { tenant } = this.props;
        let defaultIcon = '/images/marker.svg';
        let fallbackIcon = '/images/marker.png';
        if (tenant.icons) {
            defaultIcon = tenant.icons.mapMarkerSvg ||  tenant.icons.mapMarkerPng || defaultIcon;
            fallbackIcon = tenant.icons.mapMarkerPng || fallbackIcon;
        }
        const browserInfo = detectBrowser();
        // HACK: google maps library have problems displaying svg markers,
        //       so we just use png in this case.
        const useFallback = browserInfo && browserInfo.name === 'ie';
        return useFallback ? fallbackIcon : defaultIcon;
    }

    private onMarkerClick = (location: Location) => (props: any, marker: any) =>
        this.onPinClick({ ...props, locationDetails: location }, marker);

    private getInfoWindow = () => {
        const { activeMarker, selectedPlace } = this.state;
        const { study, classes, strings } = this.props;
        if (!activeMarker || !selectedPlace) {
            return null;
        }

        const infoProps = {
            onClose: this.onInfoWindowClose,
            google: this.props.google,
            marker: activeMarker,
            visible: this.state.showingInfoWindow,
        };

        const locations = selectedPlace && selectedPlace.position && this.markerDescriptions[selectedPlace.position.lat() + '_' + selectedPlace.position.lng()];
        return (
            <InfoWindow {...(infoProps as any)}>
                {locations && (locations.map((location: any, index: number) => (
                    <div key={index} className={classes.infoWindow}>
                        {location.Name && (
                            <p>
                                <strong>{location.Name}</strong>
                            </p>
                        )}
                        <div>
                            {strings.common.city}
                            <strong>{this.getInfoString(location.City)}</strong>
                        </div>
                        <div>
                            {strings.common.state}
                            <strong>
                                {this.getInfoString(location.StateDisplay)}
                            </strong>
                        </div>
                        <div>
                            {strings.common.country}
                            <strong>
                                {this.getInfoString(location.CountryDisplay)}
                            </strong>
                        </div>
                        <div>
                            {strings.common.zip}
                            <strong>
                                {this.getInfoString(location.PostalCode)}
                            </strong>
                        </div>
                        <div>
                            {strings.common.phone}
                            <strong>
                                {this.getInfoString(location.Contact.phone || study.overall_contact && study.overall_contact.phone)}
                            </strong>
                        </div>
                        <div>
                            {strings.common.email}
                            <strong>
                                {this.getInfoString(location.Contact.email || study.overall_contact && study.overall_contact.email)}
                            </strong>
                        </div>
                    </div>
                ))) || <div />}
            </InfoWindow>
        );
    };

    private getInfoString = (val: string) =>
        `: ${val || this.props.strings.common.notAvailable}`;

    private onPinClick = (props: any, marker: any) => {
        this.setState({
            activeMarker: marker,
            selectedPlace: props,
            showingInfoWindow: true,
        });

        if (marker && marker.getPosition()) {
            this.mapRef.map.setCenter(marker.getPosition());
        }
    };

    private onInfoWindowClose = () =>
        this.setState({
            activeMarker: null,
            showingInfoWindow: false,
        });

    private getContactUsButton = (location: Location) => {
        if (!location) {
            return null;
        }
        const { strings, classes, onContactUs, onAskToJoin, study } = this.props;
        const { overall_contact } = study;
        const isRecruiting = this.isRecruiting(location.StatusDisplayInternal);
        const onClick = isRecruiting ? onAskToJoin : onContactUs;
        const label = isRecruiting ? strings.common.askToJoin : strings.common.contactUs;
        const facility = {
            name: location.Name,
            address: [
                location.City,
                location.StateDisplay,
                location.CountryDisplay,
                location.PostalCode,
            ]
                .filter(el => el)
                .join(', '),
            phone: location && location.Contact && location.Contact.phone !==null ? location.Contact.phone : this.prepareString(overall_contact.phone),
            email: location && location.Contact && location.Contact.email !==null ? location.Contact.email : this.prepareString(overall_contact.email)
        };
        return (
            <Button
                className={classes.contactButton}
                onClick={() => onClick(facility)}
            >
                {label}
            </Button>
        );
    };

    private isRecruiting = (status: string) =>
        ['LOCSTATUS_RECRUITING'].some(s => s === status);
    private prepareString = (val: string) =>
    val || this.props.strings.common.notAvailable;
}

export default withStyles(styles)(
    GoogleApiWrapper({
        apiKey: process.env.GATSBY_GOOGLE_APIKEY || '',
    })(withStrings(withTenant(TrialLocation)))
);
