import { createStyles } from '@material-ui/styles';
import { Theme } from 'components/common/theme/default';

export const styles = (theme: Theme) => createStyles({

    row: {
        padding: '0 0 30px 0',        
        '& h3': {
            fontSize: '21px',
            lineHeight: '29px',
            fontWeight: 'normal',
            color: theme.color.sectionName,
        },        
        '& h4':{
            marginBottom:'0',
        },
        '& a':{
            color: '#00ACD9',
            textDecoration: 'underline',
            display:'inline-block',
        },
        '& .col-md-6':{            
            paddingBottom:'15px',
        }, 
    },
    noContact:{
        paddingTop: '15px',
    },
    errorContainer: {
        marginBottom: '15px',
    },
    formContainer: {
        '& .form-control:disabled': {
            background: 'transparent !important',
            border: '1px solid #ced4da !important',
            opacity: 0.65,
        },
        '& textarea': {
            minHeight: '7em',
        },
        '& h3': {
            fontSize: '21px',
            lineHeight: '29px',
        },
    },
    dropDownToggle: {
        width: '100%',
        background: 'transparent !important',
        color: `${theme.color.inputText} !important`,
        border: '1px solid #ced4da !important',
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important',
            borderColor: '#80bdff',
        },
        '&[aria-expanded=true]': {
            '& [data-arrow-type=down]': {
                display: 'none',
            },
        },
        '&[aria-expanded=false]': {
            '& [data-arrow-type=up]': {
                display: 'none',
            },
        },
    },
    dropDownMenu: {
        width: '100%',
        maxHeight: '30vh',
        overflowY: 'auto',
    },
    errorMsg: {
        color: theme.color.error,
    },
    submitColumn: {
        '& button': {
            [theme.breakpoints.up('md')]: {
                maxWidth: '176px',
            },
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            order: 1,
        },
        [theme.breakpoints.up('md')]: {
            order: 3,
        },
    },
});
