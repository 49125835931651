import * as React from 'react';
import { StudyDetails } from 'components/fetch/models/studyDetails';
import Modal from 'components/common/modal/modal';
import { WithStrings, withStrings } from 'components/locale';
import { Col, Row } from 'reactstrap';
import { styles } from 'components/details/readMoreModal/readMoreModal.styles';
import { WithStyles, withStyles } from '@material-ui/styles';
import { LocaleStrings } from 'components/locale/strings';
import ThemedIcon from 'components/details/themedIcon/themedIcon';

interface Props extends WithStrings<typeof getStrings>, WithStyles<typeof styles> {
    study: StudyDetails;
    onClose: () => void;
    isOpen: boolean;
}

class ReadMoreModal extends React.Component<Props> {
    private prepareString = (val: string) =>
    (!val || val ==='-' || val==="" ) ? this.props.strings.common.notAvailable : val ;
    render = () => {
        const { isOpen, onClose, classes, study, strings } = this.props;
        return (
            <Modal
                isOpen={isOpen}
                toggle={onClose}
                title={strings.inclusionAndExclusionCriteria}
            >
                <Row>
                    <Col md={2} className={classes.smTitleCol}>
                        <ThemedIcon icon={'inclusion'} />
                        <h3>{strings.inclusionCriteria}</h3>
                    </Col>
                    <Col md={10}>
                        <h3 className={classes.mdTitle}>
                            {strings.inclusionCriteria}
                        </h3>
                        <pre className={classes.messageBody}>
                            {this.prepareString(study.protocol_form.inclusion)}
                        </pre>
                    </Col>
                </Row>
                <Row className={classes.secondRow}>
                    <Col md={2} className={classes.smTitleCol}>
                        <ThemedIcon icon={'exclusion'} />
                        <h3>{strings.exclusionCriteria}</h3>
                    </Col>
                    <Col md={10}>
                        <h3 className={classes.mdTitle}>
                            {strings.exclusionCriteria}
                        </h3>
                        <pre className={classes.messageBody}>
                            {this.prepareString(study.protocol_form.exclusion)}
                        </pre>
                    </Col>
                </Row>
            </Modal>
        );
    };
}

const getStrings = ({studyDetails, common}: LocaleStrings) => ({ ...studyDetails.criteria, common });

export default withStyles(styles)(withStrings(ReadMoreModal, getStrings));
