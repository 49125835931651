import { createStyles } from "@material-ui/styles";
import { iconsStyles } from "components/common/theme/styles";
import { Theme } from "components/common/theme/default";

export const styles = (theme: Theme) =>
  createStyles({
    ...iconsStyles,
    conditionContainer: {
      paddingTop: "75px",
      borderBottom: "8px solid white",
      background: theme.color.background,
      color: theme.color.text,
      backgroundPosition: "center, right bottom",
      backgroundSize: "cover",
      "& h1": {
        lineHeight: "54px",
        fontSize: "50px",
        fontWeight: 300,
        height: "auto",
        [theme.breakpoints.down("md")]: {
          lineHeight: "29pt",
          fontSize: "21pt",
        },
      },
    },
    statusContainer: {
      background: theme.color.advancedBackground,
      padding: "40px 100px 40px 100px",
      overflow: "hidden",
      "& span": {
        margin: 0,
        color: theme.color.sectionName,
        verticalAlign: "middle",
        display: "inline-block",
        lineHeight: "45px",
        fontSize: "21px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "40px 60px 40px 60px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "10px 30px 10px 30px",
      },
    },
    statusIconContainer: {
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    attachmentsContainer: {
      paddingTop: "15px",

      "& div:first-child": {
        [theme.breakpoints.up("md")]: {
          marginLeft: "0",
        },
      },
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    goBack: {
      padding: "30px 15px 20px",
      "& button": {
        backgroundColor: "transparent",
        border: "none",
      },
      "& a, & button": {
        color: theme.color.text,
        cursor: "pointer",
        "& span": {
          verticalAlign: "middle",
          lineHeight: "32px",
          fontSize: "18px",
        },
        "&:hover": {
          textDecoration: "none",
          opacity: 0.8,
        },
        "& svg": {
          height: "30px",
          width: "30px",
        },
      },
    },
    summaryContainer: {},
    briefSummary: {
      paddingBottom: "35px",
      textAlign: "justify",
      lineHeight: "29px",
      fontSize: "16px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        lineHeight: "21px",
      },
    },
    identifications: {
      paddingBottom: "40px",
      "& span": {
        display: "block",
        fontWeight: 500,
        width: "100%",
      },
      "& .col-md-6": {
        padding: "10px 0",
        [theme.breakpoints.down("md")]: {
          lineHeight: "21px",
        },
      },
      "& .row": {
        margin: "0",
      },
      [theme.breakpoints.down("md")]: {
        paddingBottom: "30px",
      },
      "& a": {
        color: "white",
        cursor: " pointer",
        textDecoration: "none",
        borderBottom: "1px solid",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    contactUsContainer: {
      textAlign: "right",
      [theme.breakpoints.down("md")]: {
        paddingTop: "20px",
        textAlign: "center",
      },
    },
    contactUs: {
      verticalAlign: "middle",
      margin: "auto 0",
      width: "145px",
      background: "white",
      border: "1px solid #FFFFFF",
      color: "#00ACD9",
      "&:hover": {
        boxShadow:
          "0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 10px 20px 0 rgba(0, 0, 0, 0.1)",
        background: "#00ACD9",
        color: "white",
        border: "1px solid #00ACD9",
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "15px",
      },
    },
    attachments: {
      display: "inline-block",
      borderRadius: "6px",
      color: "#00ACD9",
      border: "2px solid #00ACD9",
      background: "transparent",
      textAlign: "center",
      overflow: "hidden",
      padding: " 0 15px",
      minWidth: "203px",
      height: "45px",
      margin: " 0 10px",
      "&:hover": {
        backgroundColor: "#00ACD9",
        color: "white",
        "& a": {
          color: "white",
        },
      },
      "& a": {
        color: "#00ACD9",
        verticalAlign: "middle",
        lineHeight: "45px",
        fontSize: "16px",
        fontWeight: 500,
        textDecoration: "none",
      },
      [theme.breakpoints.down("sm")]: {
        display: "block",
        margin: " 10px",
      },
    },
    shareLarge: {
      textAlign: "right",
      display: "block",
      [theme.breakpoints.down("md")]: {
        paddingRight: "60px",
      },
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
        paddingTop: "20px",
        paddingRight: "0",
      },
    },
    shareLargeText: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    topColumn: {
      maxWidth: "50%",
      flex: "0 0 50%",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "initial",
        flex: "initial",
      },
    },
  });
