import { TenantModel } from 'src/model';
import * as React from 'react';
import { TenantContext } from './tenantContext';

export interface WithTenant {
    tenant: TenantModel;
}

export function withTenant<TProps extends WithTenant>(Wrapped: React.ComponentType<TProps>) {
    return class extends React.Component<Omit<TProps, keyof WithTenant>> {
        render = () => <TenantContext.Consumer>
            {
                (tenant) => {
                    return <Wrapped {...({
                        ...this.props,
                        tenant,
                    } as any)} />;
                }
            }
        </TenantContext.Consumer>;
    };
}
