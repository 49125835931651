import * as React from 'react';
import Popover from 'components/common/popover/popover';
import InfoIcon from 'static/images/icon_info.svg';
import { defaultTheme } from 'components/common/theme/default';
import { WithStyles, withStyles } from '@material-ui/styles';
import { styles } from 'components/common/popover/inlineInfo.styles';

interface Props extends WithStyles<typeof styles> {
    header: string;
    body: string;
    color?: 'white' | 'grey';
}

class InlineInfo extends React.Component<Props> {
    render = () => {
        const { header, body, classes } = this.props;
        const color =
            this.props.color === 'white'
                ? '#fff'
                : defaultTheme.color.inputText;
        return (
            <>
                <Popover header={header} body={body}>
                    {({ targetRef }) => (
                        <div
                            ref={targetRef}
                            style={{ color }}
                            className={classes.container}
                        >
                            <InfoIcon />
                        </div>
                    )}
                </Popover>
            </>
        );
    };
}

export default withStyles(styles)(InlineInfo);
