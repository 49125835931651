import { styles } from "./trialCondition.styles";
import { withStyles, WithStyles } from "@material-ui/styles";
import * as React from "react";
import { Col, Row } from "reactstrap";
import Button from "components/common/button/button";
import { Attachment, ProtocolForm } from "components/fetch/models/studyDetails";
import { IoIosArrowBack } from "react-icons/io";
import { navigate, withPrefix } from "gatsby-link";
import InlineInfo from "components/common/popover/inlineInfo";
import { withStrings, WithStrings } from "components/locale";
import { FacilityLocation } from "components/details/contactForm/contactForm";
import classNames from "classnames";
import SocialShare from "components/search-results/socialShare/socialShare";
import { LocaleStrings } from "components/locale/strings";
import { getLookupConditionLabel } from "src/utils/studyFormHelpers";

interface Props
  extends WithStyles<typeof styles>,
    WithStrings<typeof getStrings> {
  study: ProtocolForm;
  onContactUs: (facility: FacilityLocation) => void;
}

declare global {
  interface Window {
    ga: any;
  }
}

class ConditionSection extends React.Component<Props> {
  render = () => {
    const url = new URL(location.href);
    const conditions = (formatConditionFromUrl(url.searchParams.get('condition'))) || '';
    const { classes, study, onContactUs, strings } = this.props;
    const {
      brief_summary,
      org_study_id,
      nct_number,
      display_status,
      attachments,
      overall_contact,
    } = study;
    const facility = {
      address: "",
      name: "",
      email: this.prepareString(overall_contact.email),
      phone: this.prepareString(overall_contact.phone),
    };
    return (
      <>
        <div className={classes.conditionContainer}>
          <div className={classes.goBack + " container"}>
            <Row>
              <Col className={classes.topColumn} md={4}>
                <button onClick={this.goBack}>
                  <IoIosArrowBack />
                  <span>{strings.backToSearchResults}</span>
                </button>
              </Col>
              <Col className={classes.topColumn} md={8}>
                <SocialShare
                  containerClassName={classes.shareLarge}
                  titleClassName={classes.shareLargeText}
                  title={strings.shareThisTrial}
                />
              </Col>
            </Row>
          </div>
          <div className={classes.summaryContainer + " container"}>
            <Row>
              <Col>
                <h1>
                  {"Trial Condition(s): COVID-19" +
                    `${conditions &&
                      ", " + conditions}`}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col className={classes.briefSummary}>
                {this.prepareString(brief_summary)}
              </Col>
            </Row>
            <Row className={classes.identifications}>
              <Col md={9}>
                <Row>
                  <Col md={6}>
                    <span>
                      {strings.organizationIdentifier}:{" "}
                      <InlineInfo
                        header={strings.protocolIdHeader}
                        body={strings.protocolIdInfo}
                        color={"white"}
                      />
                    </span>
                    {this.prepareString(org_study_id)}
                  </Col>
                  <Col md={6}>
                    <span>
                      {strings.clinicalTrialsGovIdentifier}:{" "}
                      <InlineInfo
                        header={strings.ctGovIdentifierHeader}
                        body={strings.ctGovIdentifierInfo}
                        color={"white"}
                      />
                    </span>

                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://clinicaltrials.gov/study/${this.prepareString(
                        nct_number
                      )}/?rank=1`}
                    >
                      {this.prepareString(nct_number)}
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col className={classes.contactUsContainer} md={3}>
                <Button
                  className={classes.contactUs}
                  onClick={() => onContactUs(facility)}
                >
                  {strings.common.contactUs}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <div className={classes.statusContainer}>
          <div className="container">
            <Row>
              <Col md={12}>
                <div className={classes.statusIconContainer}>
                  <i
                    className={classNames([classes.mediumIcon, "align-middle"])}
                    data-icon={display_status && display_status.toUpperCase()}
                  />
                  <span>{this.prepareString(display_status)}</span>
                </div>
              </Col>
              <Col className={classes.attachmentsContainer}>
                {this.getAttachmentsList(attachments)}
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  };

  private prepareString = (val: string) =>
    val || this.props.strings.common.notAvailable;

  private goBack = (e: any) => {
    e.preventDefault();
    // TODO: this is workaround. This should be saved in global state
    const searchUrl = withPrefix(
      `/search-results/${window["trialFinderSearchQuery"] || ""}`
    );
    navigate(searchUrl);
  };

  private trackLink = (event: { target: { href: string } }) => {
    if (event.target && typeof window !== "undefined" && window.ga) {
      try {
        window.ga
          .getAll()[0]
          .send("event", "Downloads", "Downloads", event.target.href);
      } catch (ev) {
        console.log("Download event: ", ev);
      }
    }
  };

  private getAttachmentsList = (attachments: Attachment[]) => {
    if (!attachments || attachments.length === 0) {
      return null;
    }
    const { classes } = this.props;

    return (
      <>
        {attachments.map((att) => {
          return (
            <div className={classes.attachments} key={att.Url}>
              <a
                onClick={(ev) =>
                  this.trackLink((ev as any) as { target: { href: string } })
                }
                href={att.Url}
                target={"_blank"}
                title={att.Title}
              >
                {att.TypeLookupValue}
              </a>
            </div>
          );
        })}
      </>
    );
  };
}

const formatConditionFromUrl = (condition: any) => {
  return condition.toString().split(',').filter((value: any)  => value && value !== 'Unmapped').filter((value: any) => value && value !== 'COVID-19')
  .filter((value: any) => value && value !== 'COVID19').join(', ') 
}

const getStrings = ({ common, studyDetails }: LocaleStrings) => ({
  ...studyDetails.trialCondition,
  common,
  studyDetails,
});

export default withStyles(styles)(withStrings(ConditionSection, getStrings));
