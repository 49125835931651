import * as React from 'react';
import Layout from 'components/layout/layout';
import Container from 'components/details/container/container';
import { TenantContext } from 'components/loadTenant/tenantContext';

export default ({ location }: { location: Location }) => {
    return (
        <Layout location={location} tenantCss={'detailsCssUrl'}>
            <TenantContext.Consumer>
                {tenant => (
                    <Container
                        location={location}
                        tenant={tenant}
                    />
                )}
            </TenantContext.Consumer>
        </Layout>
    );
};
