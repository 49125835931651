import * as React from 'react';
import Modal from 'components/common/modal/modal';
import {
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Row,
    UncontrolledDropdown,
} from 'reactstrap';
import { StudyDetails } from 'components/fetch/models/studyDetails';
import { WithStyles, withStyles } from '@material-ui/styles';
import { styles } from 'components/details/contactForm/contactForm.style';
import {
    Formik,
    Field,
    Form,
    FormikActions,
    ErrorMessage,
    FormikProps,
} from 'formik';
import Button from 'components/common/button/button';
import classNames from 'classnames';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import axios from 'axios';
import countries from 'i18n-iso-countries';
import { InputType } from 'reactstrap/lib/Input';
import ReactList from 'react-list';
import { Format, withStrings, WithStrings } from 'components/locale';
import { LocaleStrings } from 'components/locale/strings';

export type ContactType = 'contact' | 'join';

export interface FacilityLocation {
    name: string;
    address: string;
    email: string;
    phone: string;
}

type OwnStrings = ReturnType<typeof getStrings>;

interface Props extends WithStyles<typeof styles>, WithStrings<OwnStrings> {
    isOpen: boolean;
    type: ContactType;
    facility: FacilityLocation;
    tenantId: number;
    study: StudyDetails;
    onClose: () => void;
}

interface State {
    isSubmitSuccessfully: boolean;
    hasError: boolean;
    props?: Props;
}

interface SubmitModel {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    country: string;
    message: string;
}

const defaultModel = (): SubmitModel => ({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    message: '',
});

const ast = (value: string, addAsterisk: boolean = true) =>
    addAsterisk ? `${value} *` : value;

class ContactForm extends React.Component<Props, State> {
    static getDerivedStateFromProps = (
        props: Props,
        prevState: State,
    ): State => {
        if (!prevState) {
            return { isSubmitSuccessfully: false, hasError: false, props };
        }
        if (prevState.props !== props && props.isOpen) {
            return { isSubmitSuccessfully: false, hasError: false, props };
        }
        return prevState;
    };

    validateFn: ReturnType<typeof createValidationFn>;

    countryNames: string[];

    state: State = {
        isSubmitSuccessfully: false,
        hasError: false,
    };

    constructor(props: Props) {
        super(props);
        // TODO: localize
        this.countryNames = Object.entries(countries.getNames('en'))
            .map(([, name]) => name);
        this.validateFn = createValidationFn(props.strings, props.format);
    }

    render = () => {
        const { isSubmitSuccessfully } = this.state;

        return isSubmitSuccessfully
            ? this.renderSuccessModal()
            : this.renderFormModal();
    };

    renderSuccessModal = () => {
        const { strings } = this.props;
        return (
            <Modal
                isOpen={this.props.isOpen}
                title={strings.thankYouHeader}
                toggle={this.onClose}
            >
                {strings.receivedRequestBody}
            </Modal>
        );
    };

    renderForm = () => {
        const { strings, format, classes, facility } = this.props;
        const subTitle = this.formatSubtitle();

        return (
            // <Formik
            //     initialValues={defaultModel()}
            //     onSubmit={this.onSubmit}
            //     validate={this.validateFn}
            // >
            //     {props => {
            //         const { isSubmitting } = props;

            //         return (
            //             <Form>
            //                 <Row>
            //                     <Col md={4}>
            //                         <Row className={classes.row}>
            //                             <h3>{subTitle}</h3>
            //                         </Row>
            //                         <Row className={classes.row}>
            //                             {format(strings.hintText)}
            //                         </Row>
            //                     </Col>
            //                     <Col md={8} className={classes.formContainer}>
            //                         {this.renderFormInputs(props)}
            //                     </Col>
            //                 </Row>
            //                 <Row>
            //                     <Col
            //                         className={classNames(
            //                             classes.errorMsg,
            //                             'order-2',
            //                         )}
            //                         md={9}
            //                         sm={12}
            //                     >
            //                         <div>
            //                             {this.state.hasError
            //                                 ? strings.common.networkError
            //                                 : null}
            //                         </div>
            //                     </Col>
            //                     <Col
            //                         className={classNames(
            //                             classes.submitColumn,
            //                             classes.errorContainer,
            //                             'text-right',
            //                         )}
            //                         md={3}
            //                         sm={12}
            //                     >
            //                         <Button
            //                             type={'submit'}
            //                             disabled={isSubmitting}
            //                         >
            //                             {strings.submitRequest}
            //                         </Button>
            //                     </Col>
            //                 </Row>
            //             </Form>
            //         );
            //     }}
            // </Formik>
            
            <Row className={classes.row}>
                <Col md={6}>
                    <h4>Email:</h4>
                    {facility.email != 'Not Available' ? (<a href={`mailto:${facility.email}`}>{facility.email}</a>) : facility.email }
                </Col>
                <Col md={6}>                    
                    <h4>Phone:</h4>
                    {facility.phone != 'Not Available' ? (<a href={`tel:${facility.phone}`}>{facility.phone}</a>) : facility.phone }                    
                </Col>
                
                {this.noContactMessage() && 
                (<Col xs={12} className={classes.noContact}>
                    {this.noContactMessage()}
                </Col>)
                }
               
            </Row>
            
        );
    };

    private formatSubtitle() {
        const { type, strings, format, study } = this.props;
        const url = new URL(location.href);
        const args = { title: url.searchParams.get('title') || '' };
        const subTitle = type === 'join'
            ? format(strings.askToJoinInStudy, args)
            : format(strings.contactUsForStudy, args);

        return subTitle;
    }

    private noContactMessage(){        
        const { strings,  facility } = this.props;
        if (facility.email==="Not Available" && facility.phone === 'Not Available'){
            return strings.common.noContactsMessage;
        }else if(facility.email==="Not Available"){
            return strings.common.noEmailContactMessage;
        } else if (facility.phone==="Not Available"){
            return strings.common.noPhoneContactMessage;
        }else {
            return ''
        }
    }

    private renderFormInputs(formikProps: FormikProps<SubmitModel>) {
        const { classes, strings } = this.props;
        const {
            handleChange,
            isSubmitting,
            values,
            setFieldValue,
        } = formikProps;

        const renderError = (msg: string) =>
            msg ? <div className={classes.errorMsg}>{msg}</div> : null;

        const inputCol = (
            id: keyof SubmitModel,
            placeHolder: string,
            inputType: InputType = 'text',
        ) => (
            <Col md={6} sm={12} className={classes.errorContainer}>
                <Input
                    id={id}
                    name={id}
                    placeholder={ast(placeHolder)}
                    type={inputType}
                    disabled={isSubmitting}
                    onChange={handleChange}
                />
                <ErrorMessage name={id} render={renderError} />
            </Col>
        );
        const onItemChange = (evt: React.MouseEvent<HTMLElement>) => {
            let value = (evt.target as any).getAttribute('data-value');
            value = value || '';
            setFieldValue('country', value);
        };

        return (
            <>
                <Row>
                    {inputCol('firstName', strings.firstName)}
                    {inputCol('lastName', strings.lastName)}
                </Row>
                <Row>
                    {inputCol('email', strings.common.email, 'email')}
                    {inputCol('phone', strings.common.phone, 'tel')}
                </Row>
                <Row>
                    <Col className={classes.errorContainer}>
                        <UncontrolledDropdown>
                            <DropdownToggle
                                disabled={isSubmitting}
                                className={classNames(
                                    classes.dropDownToggle,
                                    'text-left clearfix',
                                )}
                            >
                                <span>
                                    {values.country || ast(strings.common.country)}
                                </span>
                                <span
                                    style={{ float: 'right' }}
                                    data-arrow-type={'down'}
                                >
                                    <IoIosArrowDown />
                                </span>
                                <span
                                    style={{ float: 'right' }}
                                    data-arrow-type={'up'}
                                >
                                    <IoIosArrowUp />
                                </span>
                            </DropdownToggle>
                            <DropdownMenu
                                // HACK: IE have junky mouse scrolling when there are nested
                                //       transforms. Disabling it seems to fix the issue
                                style={{ transform: 'none !important' }}
                                placeholder={ast(strings.common.country)}
                                onClick={onItemChange}
                                className={classes.dropDownMenu}
                            >
                                <ReactList
                                    length={this.countryNames.length}
                                    itemRenderer={this.renderItem}
                                    type={'uniform'}
                                    useTranslate3d={true}
                                    useStaticSize={true}
                                />
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <ErrorMessage name={'country'} render={renderError} />
                    </Col>
                </Row>
                <Row>
                    <Col className={classes.errorContainer}>
                        <Field
                            disabled={isSubmitting}
                            name='message'
                            component='textarea'
                            className={'form-control'}
                            placeholder={ast(strings.yourMessage)}
                        />
                        <ErrorMessage
                            name={'message'}
                            component={'div'}
                            render={renderError}
                        />
                    </Col>
                </Row>
            </>
        );
    }

    private renderItem = (index: number, key: any) => {
        return <DropdownItem key={key} data-value={this.countryNames[index]}>
            {this.countryNames[index]}
        </DropdownItem>;
    };

    private renderFormModal = () => {
        const { type, strings } = this.props;
        const title = type === 'join' ? strings.common.askToJoin : strings.common.contactUs;

        return (
            <Modal
                isOpen={this.props.isOpen}
                title={title}
                toggle={this.onClose}
            >
                {this.renderForm()}
            </Modal>
        );
    };

    private onSubmit = async (
        values: SubmitModel,
        { setSubmitting }: FormikActions<SubmitModel>,
    ) => {
        const { study, facility, tenantId } = this.props;
        const apiModel = {
            TrialId: study.protocol_form.org_study_id,
            TrialTitle: study.protocol_form.official_title,
            From: values.firstName + ' ' + values.lastName,
            Email: values.email,
            Phone: values.phone,
            Country: values.country,
            Message: values.message,
            LocationName: facility.name,
            LocationAddress: facility.address,
        };
        try {
            await axios.post(
                `${process.env.GATSBY_STAPI_URL}api/patientdatarequestform/TrialFinderContactUS?tenantId=${tenantId}`,
                apiModel,
            );
            this.setState({ isSubmitSuccessfully: true });
        } catch (ex) {
            this.setState({ hasError: true });
            console.log('error', ex);
        } finally {
            setSubmitting(false);
        }
    };

    private onClose = () => {
        this.props.onClose();
    };
}

function createValidationFn(strings: OwnStrings, format: Format) {
    const required = (value: string, name: string) =>
        value ? undefined : format(strings.fieldIsRequired, { fieldName: name });
    const regex = (exp: RegExp) => (value: string, name: string) => {
        if (!exp.test(value)) {
            return format(strings.fieldIsRequired, { fieldName: name });
        }
        return undefined;
    };
    const validationModel: {
        [key in keyof SubmitModel]: [
            string,
            (value: SubmitModel[key], name: string) => string | void
        ];
    } = {
        country: [strings.common.country, required],
        firstName: [strings.firstName, required],
        lastName: [strings.lastName, required],
        message: [strings.yourMessage, required],
        phone: [strings.common.phone, regex(/[+\d\-() ]+/)],
        email: [
            strings.common.email,
            regex(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i),
        ],
    };
    return (model: SubmitModel) => {
        const errors = {};
        for (const [field, [name, fn]] of Object.entries(validationModel)) {
            const error = fn(model[field], name);
            if (error) {
                errors[field] = error;
            }
        }
        return errors;
    };
}

const getStrings = ({ studyDetails, common }: LocaleStrings) => ({ ...studyDetails.contactForm, common });

export default withStyles(styles)(withStrings(ContactForm, getStrings));
