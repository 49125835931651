import * as React from 'react';
import { styles } from './container.styles';
import { withStyles, WithStyles } from '@material-ui/styles';
import { GetStudyDetails } from 'components/fetch/stApi';
import { StudyDetails } from 'components/fetch/models/studyDetails';
import ContactForm, {
    ContactType,
    FacilityLocation,
} from 'components/details/contactForm/contactForm';
import { TenantModel } from 'src/model';
import TrialCondition from 'components/details/trialCondition/trialCondition';
import TrialPurpose from 'components/details/trialPurpose/trialPurpose';
import TrialSummary from 'components/details/trialSummary/trialSummary';
import TrialLocation from 'components/details/trialLocation/trialLocation';
import ReadMoreModal from 'components/details/readMoreModal/readMoreModal';
import {Helmet} from "react-helmet";
import dataMock from "./study_details.json";

interface Props extends WithStyles<typeof styles> {
    location: Location;
    tenant: TenantModel;
}

interface State {
    modalIsOpen: boolean;
    readMoreModalIsOpen: boolean;
    modalType: ContactType;
    facility: FacilityLocation;
}

class DetailsContainer extends React.Component<Props, State> {
    state: State = {
        modalType: 'contact',
        modalIsOpen: false,
        readMoreModalIsOpen: false,
        facility: {
            name: '',
            address: '',
            email: '',
            phone: '',
        },
    };

    render = () => {
        const { classes, location } = this.props;
        const url = new URL(location.href);
        //TODO: handle empty id
        const studyId = url.searchParams.get('id') || '';
        return (
            <div className={classes.detailsContainer}>
                <GetStudyDetails studyId={studyId}>
                    {({ data, error }) => {
                        if (data) {
                            return this.getDetails(data);
                        }
                        if (error) {
                            return 'Error fetching codes';
                        }
                        return this.getDetails(dataMock);
                    }}
                </GetStudyDetails>
            </div>
        );
    };

    private getDetails = (study: StudyDetails) => {
        const {
            modalIsOpen,
            modalType,
            readMoreModalIsOpen,
            facility,
        } = this.state;
        const url = new URL(location.href);
        const searchLat = url.searchParams.get('searchLat');
        const searchLong = url.searchParams.get('searchLong');
        const searchRadius = url.searchParams.get('searchRadius');
        const baseLat = url.searchParams.get('baseLat');
        const baseLong = url.searchParams.get('baseLong');
        const studyTitle = url.searchParams.get('title') || '';
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{studyTitle}</title>
                    <meta property='title' content={studyTitle} />
                    <meta content={studyTitle} property="og:title" />
                    <meta content={studyTitle} name="twitter:title" />
            
                    <meta content={study.protocol_form.official_title} name="description" />  
                    <meta content={study.protocol_form.official_title} property="og:description" /> 
                    <meta content={study.protocol_form.official_title} name="twitter:description" /> 
                </Helmet>
                <TrialCondition
                    study={study.protocol_form}
                    onContactUs={this.onContactUs}
                />
                <ContactForm
                    isOpen={modalIsOpen}
                    type={modalType}
                    facility={facility}
                    study={study}
                    tenantId={this.props.tenant.id}
                    onClose={this.onModalClose}
                />
                <ReadMoreModal
                    isOpen={readMoreModalIsOpen}
                    study={study}
                    onClose={this.onReadMoreClose}
                />
                <TrialPurpose
                    study={study.protocol_form}
                    onReadMore={this.onReadMore}
                />
                <TrialSummary study={study.protocol_form} />
                <TrialLocation
                    study={study.protocol_form}
                    onContactUs={this.onContactUs}
                    onAskToJoin={this.onAskToJoin}
                    searchLat={searchLat}
                    searchLong={searchLong}
                    searchRadius={searchRadius}
                    baseLat={baseLat}
                    baseLong={baseLong}
                />
            </>
        );
    };

    private onContactUs = (facility: FacilityLocation) =>
        this.setState({ modalIsOpen: true, modalType: 'contact', facility });

    private onModalClose = () => this.setState({ modalIsOpen: false });

    private onAskToJoin = (facility: FacilityLocation) =>
        this.setState({ modalIsOpen: true, modalType: 'join', facility });

    private onReadMore = () => this.setState({ readMoreModalIsOpen: true });

    private onReadMoreClose = () =>
        this.setState({ readMoreModalIsOpen: false });
}

export default withStyles(styles)(DetailsContainer);
