import { createStyles } from '@material-ui/styles';
import { Theme } from 'components/common/theme/default';

export const styles = (theme: Theme) => createStyles({
    trialHeader: {
        color: theme.color.title,
        lineHeight: '44px',
        fontSize: '32px',
        display: 'block',
        fontWeight: 400,
    },
    trialLabel: {
        color: theme.color.summaryLabel,
        lineHeight: '36px',
        minHeight: '36px',
        fontSize: '26px',
        fontWeight: 400,
        [theme.breakpoints.down('md')]: {
            lineHeight: '26px',
            fontSize: '22px',
        },
    },
    trialSection: {
        padding: '60px 0 40px 0',
    },
    trialSubsection: {
        padding: '40px 0 20px 0',
    },
});
