import { createStyles } from '@material-ui/styles';

export const styles = createStyles({
    container: {
        display: 'inline',
        marginLeft: '0.2em',
        width: '17px',
        height: '17px',
        '& svg': {
            verticalAlign: 'top !important',
        },
    },
});
