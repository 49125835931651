import * as React from 'react';
import { Popover as BootstrapPopover, PopoverBody } from 'reactstrap';
import { WithStyles, withStyles } from '@material-ui/styles';
import { styles } from 'components/common/popover/popover.styles';
import parseHtml from 'html-react-parser';

interface PopoverFnArgs {
    targetRef: React.Ref<any>;
}

interface Props extends WithStyles<typeof styles> {
    header?: string;
    body: string;
    children: (args: PopoverFnArgs) => React.ReactNode;
}

interface State {
    isOpen: boolean;
    targetRef: React.RefObject<HTMLElement>;
}

class Popover extends React.Component<Props, State> {
    state: State = {
        isOpen: false,
        targetRef: React.createRef<HTMLElement>(),
    };

    render = () => {
        const { header, body, children } = this.props;
        const { targetRef } = this.state;

        const childNode = children({ targetRef });
        return (
            <>
                {childNode}
                <BootstrapPopover
                    isOpen={this.state.isOpen}
                    placement={'right-start'}
                    trigger={'hover'}
                    boundariesElement={'window'}
                    delay={{ show: 0, hide: 100 }}
                    target={this.getTarget as any}
                    offset={500}
                    hideArrow={true}
                    toggle={this.toggle}
                    modifiers={{
                        flip: {
                            enabled: true,
                            behavior: 'counterclockwise',
                        },
                    }}
                >
                    <PopoverBody>
                        <div>
                            {header && <h3>{header}</h3>}
                            <div>{parseHtml(body)}</div>
                        </div>
                    </PopoverBody>
                </BootstrapPopover>
            </>
        );
    };

    getTarget = () => {
        const { targetRef } = this.state;
        if (!targetRef.current) {
            console.warn('targetRef was not set for Popover!');
        }
        return targetRef.current!;
    };

    toggle = () => this.setState({ isOpen: !this.state.isOpen });
}

export default withStyles(styles)(Popover);
